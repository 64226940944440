import React, { FunctionComponent } from 'react';
import Section from "../components/Section";
import StartCompanyImage from "../assets/images/start-company.svg";
import Button from "../components/Button/Button";
import StartYourBusiness from "../assets/images/start-your-business.svg";
import Heading from "../components/Heading";
import {Link} from "gatsby";
import FeaturedPostsSection from "../sections/FeaturedPostsSection";
import FaqIcon from "../assets/images/faq.svg";
import Faqs from "../components/Faqs";
import HeroVideo from "../components/HeroVideo";

type SroProps = {
};

const offers = [
    {
        title: 'Creation of company',
        text: 'Fillout the form and we will get back to you with all important information. We walk you through the whole process and discuss your options.',
    },
    {
        title: 'Payment',
        text: 'One time fee for handling all paper work and discussion with state institutions for you. ',
    },
    {
        title: 'Sign documents',
        text: 'Even when founding S.R.O. via the Internet, the state requires a notarized signature of the charter, resp. social contract. This will be your only way to a notary or registry office to verify your signature. ',
    },
    {
        title: 'Company registration',
        text: 'After receiving the documents, we will submit a proposal for the registration of the company in the Commercial Register, ensure the acquisition of a trade license and cover further communication with the authorities.',
    },
    {
        title: 'Start a business',
        text: 'The whole process of establishing s.r.o. usually takes a maximum of 14 days. We will inform you about the successful registration of the company by SMS message and email, in which we will simultaneously send you a confirmation of the registration of the company in the commercial register.',
    },
];

const SroVideoFold: FunctionComponent<SroProps> = (props) => {

    return (
        <>
            <Section className='py-8' fullWidth>

                <div>
                    <HeroVideo
                        headline={"S. R. O. — Limited Liability Company"}
                        subheadline={"Spoločnosť s ručením obmedzeným — S. R. O. — is capital company that is created for bigger companies that will have larger investments as well as expenses. "}
                        // heroImage={SroHeroImage}
                        videoId={"https://www.youtube.com/watch?v=74xnIm3tL1I"}
                        buttons={(
                            <div>
                                <Link to={'/order-form'}>
                                    <Button>
                                        Setup your S. R. O.
                                    </Button>
                                </Link>
                                <Link to={'#offer'}>
                                    <Button className={'ml-4'} variant={'border'}>
                                        Learn more
                                    </Button>
                                </Link>
                            </div>
                        )}
                        outerContainer
                    />
                </div>
            </Section>
            <Section className={'py-20'}>
                <div className={'text-center mb-20'}>
                    <Heading id="offer" type='h3' className={'text-ink-40'}>Our offer is simple —</Heading>
                    <Heading type={'h2'}>
                        We handle everything for you.
                    </Heading>
                </div>
                <div className={'grid grid-cols-1 md:grid-cols-2 gap-y-32 gap-x-8 mb-12'}>
                    {offers.map((offer, index) => (
                        <div className={'flex'}>
                            <div className={'flex w-1/5 justify-center mr-4 items-start'}>
                                <h3 className={'font-sans text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-b from-brand-100 to-white'}>
                                    {index+1}
                                </h3>
                            </div>
                            <div className={'w-2/3'}>
                                <Heading type="h5" className={'font-semibold'}>{offer.title}</Heading>
                                <p className={'text-ink-60 mt-2'}>{offer.text}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </Section>
            <Section className={'py-20 bg-ink-5'}>
                <div className="grid grid-cols-2 gap-x-16">
                    <div>
                        <p className={'font-caveat text-2xl font-bold text-brand-100'}>Starts at 290 € </p>
                        <p className={'font-caveat text-xl font-bold text-ink-40 mb-3'}>+ 150 €  State fee</p>
                        <Heading type='h2' className={'mb-8'}>It costs nothing compared to time and money spent doing it on your own.</Heading>
                        <Button variant="secondary">Start Ltd. Company</Button>
                    </div>
                    <div className={'ml-8'}>
                        <img src={StartCompanyImage} alt=""/>
                    </div>
                </div>
                <div className={'border-t border-ink-20 mt-16 py-6'}>
                    <p className={'text-sm text-ink-40'}>The price includes the preparation of sample documents for the proposal for registration of the company in the commercial register, for the tax office and trade license office, the fee for the business register, any number of free trades, costs of founding a company and professional administrative equipment. The price includes VAT, is final and without hidden fees.</p>
                </div>
            </Section>
            <Section className={'py-20 bg-blue-120 text-white'}>
                <div className={'flex mb-16'}>
                    {/*<div className={'w-full lg:w-1/2 flex justify-center lg:order-last mb-10 lg:mb-0'}>*/}
                    {/*    <img src={StartYourBusiness} alt=""/>*/}
                    {/*</div>*/}
                    <div className={'w-full text-center'}>
                        <Heading type='h3'>Enjoy and relax — </Heading>
                        <Heading type='h2'>We start your business for you</Heading>
                    </div>
                </div>
                <div className={'grid grid-cols-1 lg:grid-cols-3 gap-24 text-white'}>
                    {Array(6).fill().map(() => (
                        <div>
                            <Heading type='h5' className={'font-semibold'}>Registration with authorities</Heading>
                            <p className={'text-ink-10'}>It usually takes 3-5 business days for state to process your request. After that, you will be provided with all documents that confirm your business in Slovakia.</p>
                        </div>
                    ))}
                </div>
                <div className={'text-center mt-16'}>
                    <Button variant="secondary">Start your business</Button>
                </div>

            </Section>
            <Section className={'py-20'}>
                <div className={'flex mb-16 flex-wrap justify-center'}>
                    <div className={'w-full lg:w-2/5 flex justify-center items-start lg:order-last mb-10 lg:mb-0'}>
                        <img src={FaqIcon} alt="" className={'w-full max-w-lg'}/>
                    </div>
                    <div className={'w-full lg:w-3/5'}>
                        <Heading type='h3' className={'text-ink-40'}>Frequently asked —</Heading>
                        <Heading type='h2'>Questions & Answers</Heading>
                        <div className={'py-12 sm:py-16'}>
                            <Faqs items={[]}/>
                        </div>
                        <Button variant="border">Show all</Button>
                    </div>
                </div>
            </Section>
            <FeaturedPostsSection/>

        </>
    );
};

export default SroVideoFold;
