import React, { FunctionComponent } from 'react';
import classNames from "classnames";
import ReactPlayer from "react-player";
import VideoOverlay from "../assets/images/video-overlay.png";
import VideoDecoration from "../assets/images/video-decoration.png";
import PlayIcon from "../assets/icons/ico_play.svg";

type Props = {
    headline: string,
    subheadline?: string,
    videoId?: string,
    buttons: any,
    outerContainer?: boolean
};

const HeroVideo: FunctionComponent<Props> = ({ headline, subheadline, videoId, buttons, outerContainer= false }) => {
    const outerPadding = videoId ? 'pt-20 pb-32' : '';

    return (
        <div className={outerContainer ? 'container' : ''}>
            <div className={`${outerPadding} mobile-bg-none`}>
                <div className={outerContainer ? '' : 'container'}>
                    <div className={'grid lg:grid-cols-2'}>
                        <div>
                            <h1 className={'text-h1 font-bold font-sans mb-4'}>{headline}</h1>
                            { subheadline ? (<p className={ ' mb-8'}>{subheadline}</p>) : null }
                            { buttons ? (buttons) : null}
                        </div>
                        <div className={'flex justify-center lg:justify-end'}>
                            <div className={'relative'}>
                                <div className={'relative z-10 h-full'}>
                                    <ReactPlayer
                                        url={videoId}
                                        width="540px"
                                        height="360px"
                                        playing
                                        playIcon={<button>
                                            <div className={'bg-white w-12 h-12 rounded-full flex justify-center items-center'}>
                                                <PlayIcon/>
                                            </div>
                                        </button>}
                                        light={VideoOverlay}
                                    />
                                </div>
                                <img src={VideoDecoration} className={'absolute bottom-0 -right-20'} alt=""/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default HeroVideo;
